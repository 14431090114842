/*    
 *  _________________________________________
 * / Stop right there, ya dingus. This file  \
 * \ should not be edited.                   /
 *  -----------------------------------------
 *  \
 *   \
 *      __
 *     /  \
 *     |  |
 *     @  @
 *     |  |
 *     || |/
 *     || ||
 *     |\_/|
 *     \___/
 *    
 * This file is automatically generated through https://localise.biz.
 *                                                                         
 * To update this file:
 *
 *  $ npm run update-language
 *
 * Last update:    Tue Feb 11 2025 14:09:22 GMT+0100 (Central European Standard Time)
 * Language keys:  11
 * Language code:  en
 *
 */

const language = {
  "resources": {
    "alarms": {
      "name": "Alarm |||| Alarms",
      "create_title": "Create alarm",
      "text": {
        "alarm_creator": "Alarm creator",
        "alarm_creator_explanation": "Select who or what is creating the alarm. The position, groups and response configuration of this object or person will be used during the alarm handling process.",
        "alarm_information": "Alarm information",
        "alarm_information_explanation": "The information below is used to determine what should happen during the alarm. Choose a short alarm name (e.g. 'Panic alarm') and select the alarm urgency.",
        "severity_options": {
          "red": "Red (urgent)",
          "orange": "Orange (requires attention)",
          "green": "Green (non-urgent)"
        },
        "type_options": {
          "panic": "Panic",
          "assistance": "Assistance",
          "test": "Test",
          "outage": "Outage",
          "fire": "Fire",
          "medical": "Medical",
          "mandown": "Man down",
          "other": "Other"
        },
        "procedure_placeholder": "The procedure is shown in the X-Guard Alarm Manager and X-Guard Alarm App.\n\nDescribe what people who are coming to help need to do.",
        "ack_definition": "Require acknowledgement",
        "ack_explanation": "By default, alarms require acknowledgement. Unacknowledged alarms can be interacted with through the X-Guard Alarm Manager and X-Guard Alarm App.",
        "ack": {
          "no_time": "No time set",
          "open_alarm": "Open alarm",
          "not_needed": "Not needed",
          "acked": "Acknowledged alarm",
          "value": "Requires acknowledgement (recommended) "
        },
        "page_info": "This page shows a quick overview of alarms in your organisation. Open the X-Guard Alarm Manager for more details about an alarm.",
        "acknowledge_all": "Acknowledge all",
        "acknowledge_confirmation": "Are you sure you want to acknowledge %{length} alarms?",
        "acknowledge_selected": "Acknowledge selected",
        "acknowledge": "Acknowledge",
        "bhvk": {
          "create_title": "Make alarm",
          "create_subtitle": "Make a demo alarm by pressing the pulsing button on the left. When pressed, you will be notified either in the app or via a phonecall."
        },
        "how_to_receive_title": "How would you like to receive the alarms?",
        "how_to_receive_subtitle": "If the app is downloaded, the system will use the app first to notify you of new alarms. If you only want to be called, remove the app.",
        "receive_options": {
          "call": "Getting a call",
          "app": "Via the app"
        },
        "app_explanation": {
          "1": "1. Install the app",
          "2_title": "2. App login",
          "2_2": "Your username for logging in is: ",
          "2_3": "The app will further instruct you.",
          "1_title": "3. Ready to make alarms",
          "3_2": "Press the button to make an alarm",
          "under_buttons": "The app can be downloaded in the App store or on Google Play"
        },
        "bhvk_alarm_created": "Alarm created. You will receive this alarm via the selected method.",
        "simulation_result": "The following actions will be performed:",
        "no_alarms": "There where no alarms made in the last 7 days.",
        "notified": "Notified",
        "will_help": "Will help",
        "will_not_help": "Will not help",
        "nobody_notified": "Nobody, read why here",
        "view_alarms": "View alarms",
        "create_an_alarm": "Create an alarm",
        "title_subtext": "On this page you can find an overview of all alarms.",
        "no_links": "No links specified for response"
      },
      "fields": {
        "name": "Alarm name",
        "severity": "Severity",
        "type": "Alarm type",
        "procedure": "Procedure",
        "ack": {
          "name": "Needs acknowledgement",
          "value": "Acknowledged"
        },
        "alarmCenter": {
          "name": "Alarm Center"
        },
        "customer": {
          "_id": "Customer"
        },
        "mergeHistory": "Deduplicated"
      }
    },
    "assets": {
      "name": "Asset |||| Assets",
      "text": {
        "annotation": "This page does not show any app users",
        "without_app_title": "Assets without app",
        "single_name": "Asset",
        "general": "General",
        "properties": "Properties",
        "response": "Response",
        "app": "App",
        "app_mode_not_editable": "App mode cannot be edited",
        "app_available_explanation": "When available is on, the app user can receive requests to help with the alarms of nearby users.",
        "download_history_button": "Download history",
        "delete_user": "Delete user",
        "groups_updated": "Groups updated",
        "edit_groups": "Edit groups",
        "without_app_description": "Assets are people or objects that are not tied to the X-Guard Alarm app. You can view and edit assets here. Adding and removing assets is not possible because they are sometimes used for advanced configurations in your organization. In most cases, you want to create a [User](%{adminPath}) or [App user](%{appUserPath}) instead of an asset.",
        "has_app": "Is app-account",
        "add_bhv": "Add BHV'er",
        "add_user": "Add user",
        "add_user_tooltip": "After ordering you can add users",
        "add_bhv_tooltip": "After ordering you can add BHV'ers",
        "bhvk_deleted": "User deleted",
        "number_of_available_users": "Total number of users available for receiving alarms"
      },
      "fields": {
        "user": {
          "_id": "User"
        },
        "externalId": "External ID",
        "description": "Description",
        "phoneNumber": "Phone number",
        "smsNumber": "SMS number",
        "email": "Email",
        "image": "Image",
        "available": "Available",
        "staticResponders": {
          "name": "Static responders",
          "staticResponder": "Static responder",
          "priority": "Priority"
        },
        "position": {
          "provider": "Position is static"
        },
        "invisibleResponder": "Is invisible responder",
        "allowContactOnOwnAlarms": "Allow contact on own alarms (Show phone number in Alarm manager)",
        "lastObservationAt": "Last observation at",
        "insideZones": "Inside zones",
        "can_receive": "On-call for others",
        "my_security": "My security",
        "last_active_at": "Last active at",
        "has_app": "Uses the app"
      },
      "download_history_for_last": "Download history for last:"
    },
    "app-users": {
      "text": {
        "sent_configuration": "Configuration sent to device",
        "sent_configuration_failed": "Failed to send configuration to device",
        "sent_log": "Log request sent to device",
        "sent_log_failed": "Failed to send log request to device",
        "actions": "Actions",
        "send_critical_title": "Send as critical",
        "send_critical_explanation": "This switch indicates if the notification should be sent as critical. Set the switch to the desired position\n                before clicking the button to send the notification.",
        "configuration_update_title": "Configuration update",
        "configuration_notification_title": "Send Configuration Notification",
        "configuration_notification_explanation": "This will send a notification to the device with the request to click on the notification.\n                If they do, their configuration is reloaded immediately",
        "log_notification_title": "Request device log",
        "log_notification_explanation": "This will send a notification to the device with the request to click on the notification.\n                If they do, the device uploads a log to development team.",
        "device_info": "Device info",
        "installed_version": "Installed version",
        "available_version": "Available version",
        "compliance_state": "Compliance state",
        "compliance_true": "In compliance",
        "compliance_false": "Not in compliance",
        "device_profile": "Device profile",
        "permissions": "Permissions (only for the current OS)",
        "saving_group_successful": "Saving groups successful",
        "saving_group_failed": "Saving groups failed",
        "config": "Config",
        "group_title": "Groups",
        "group_explanation": "If two groups edit the same configuration keys, the last group will overwrite the earlier defined groups. Make sure the group order is correct.",
        "last_sync": "Last sync",
        "last_config_update": "Last config update",
        "last_config_compliance": "Last config compliance",
        "reset_groups": "Reset groups",
        "save_groups": "Save groups",
        "no_valid_config": "This user has no valid device config and cannot be edited",
        "download_group": "Group? Leave empty to have no group attached.",
        "view_device_json": "View console for device JSON",
        "configuration": "Configuration",
        "show_unmodified_keys": "Show unmodified keys",
        "delete_success": "Deleted App users",
        "delete_success_single": "Deleted App user",
        "delete_failed": "Deleting App users failed",
        "delete_failed_single": "Deleting App user failed",
        "delete_title": "Delete App users",
        "delete_all_devices": "Delete all devices",
        "updated": "App users updated",
        "title": "App users",
        "created": "App user created! The user can now log in with %{username} via the X-Guard Alarm app",
        "create_explanation": "To register, open the X-Guard Alarm App and scan the organisation QR code",
        "page_info": "App users have the ability to login to the X-Guard Alarm App. App users can not login to our dashboards.",
        "group_and_config_button_title": "Groups and config",
        "config_and_groups_updated": "Groups and config updated!",
        "fill_phonenumber": "Enter a phone number first",
        "available_helper": "If \"Receives alarms\" is turned on, this account will actively recieve eligable alarms. This happens either via a phonecall or, if logged in, via the app.",
        "warning_sends_email": "Please note: this registration profile sends an email to the new user",
        "create_continue": "How do you want to continue?",
        "create_modal_intro": "App users can be registered in two ways. You can let the user do this themselves by scanning a QR code when opening the app, or you can create the user yourself.",
        "create_modal_open_qrcodes": "Open QR Codes",
        "create_modal_manually_create_user": "Manually create user",
        "devlog_notification_title": "Request device dev log",
        "go_to_connected_users": "Go to connected user"
      },
      "copy_device_json": "Copy device JSON",
      "text_create_register_via_qr": "User needs to register themselves via the QR code",
      "text_create_continue_to_create": "I will manually create the App user"
    },
    "asset-groups": {
      "fields": {
        "description": "Description",
        "asset_count": "Number of persons",
        "assets": "Assets"
      },
      "text": {
        "title": "Asset groups",
        "title_single": "Group",
        "page_info": "Groups are used by certain behind-the-scenes configurations which might affect alarm response and creation.\n\nYou can use this page to verify that your groups are defined as expected.",
        "no_assets": "No persons of objects",
        "create_title": "Create new group",
        "create_explanation": "Groups can be used to differentiate between users. X-Guard is able to  link various configurations for the app and alarm response to a group.",
        "add_person": "Add person",
        "delete_warning": "This group is used in configuration for alarm handling or on a kiosk.",
        "delete_warning_multiple": "One or more of the selected groups are used to configure how alarms are handled.",
        "delete_confirmation": "I understand that by proceeding the alarm procedure might break. I will not remove this group unless I know for sure that the alarm procedure is still working correctly."
      },
      "name": "Groups"
    },
    "beacons": {
      "text": {
        "share_types": {
          "public": "Public",
          "default": "Default"
        },
        "code": "Code",
        "title": "Beacons",
        "low_battery": "Beacon has low battery",
        "beacon_last_seen": "Beacon not seen lately",
        "beacon_healthy": "Beacon healthy",
        "in_last_hour": "In the last hour"
      },
      "fields": {
        "codes": "Codes",
        "rssiAddend": "RSSI addend",
        "zone": {
          "_id": "Zone"
        },
        "state": {
          "lastSeenAt": "Last seen at"
        },
        "share": {
          "type": "Share type"
        },
        "beacon_code": "Beacon code"
      }
    },
    "customer-links": {
      "fields": {
        "groups": "Groups",
        "alarmLevels": "Alarm levels",
        "processTemplate": {
          "id": "Process template"
        },
        "level": {
          "green": "Green",
          "orange": "Orange",
          "red": "Red",
          "mandown": "Mandown"
        },
        "groupType": "Group type"
      },
      "text": {
        "title": "Customer links",
        "group_types": {
          "all": "All groups",
          "none": "No groups",
          "some": "Some groups",
          "not_in": "Not in specific groups"
        },
        "types_placeholder": "When no types are selected, the customer-link will be active for all types"
      }
    },
    "process-templates": {
      "text": {
        "no_asset_error": "No asset selected",
        "simulation_error": "Error in simulation",
        "previous_stage_delay": "Delay from previous stage: %{delay} seconds",
        "query_speed": "Query speed",
        "alarmcenter_escalate": "Escalate",
        "alarmcenter_queue_name": "Queue name",
        "no_results_in_stage": "No results in this stage",
        "kilometers": "kilometers",
        "asset_distance": "Distance",
        "simulate_process": "Simulate ATS process",
        "simulate": "Simulate",
        "simulation_started": "Simulation started",
        "simulation_started_secondary": "ATS is simulating, please wait",
        "title": "Process templates",
        "if_script": "If script",
        "taskTemplate": {
          "id": "Task template"
        },
        "schedule_tasks": "Schedule tasks"
      },
      "fields": {
        "processName": "Process name",
        "description": "Description",
        "delay": "Delay"
      }
    },
    "users": {
      "fields": {
        "username": "Username",
        "group_restrictions": "Restrictions (by group)",
        "roles": "Roles",
        "lastActiveAt": "Last active at",
        "map_viewer": "Has access to Live map"
      },
      "text": {
        "password_reset_sent": "Password reset request sent",
        "password_reset_failed": "Password reset request failed",
        "password_reset_button": "Send password reset e-mail",
        "password_reset_to_base": "Reset to default password",
        "password_reset_button_description": "Are you sure that you want to reset the password of this user? They will be required to set a new password on first login",
        "password_reset_to_base_description": "Are you sure that you want to send a password reset email to this user?",
        "password_reset_to_base_confirm": "Reset password",
        "password_reset_mail_confirm": "Send email",
        "roles": {
          "app_user": "App User - do not set",
          "map_viewer": "Map viewer",
          "centralist": "Centralist",
          "customer_admin": "Customer admin",
          "viewer": "Viewer",
          "alarm_responder": "Alarm responder",
          "ats_responder": "ATS responder",
          "kioskUser": "Display user",
          "customer_admin_external_id": "Can add or edit external ID",
          "customer_admin_special_username": "Can create users with a username not based on the email address",
          "flic_hub_tester": "Flic hub tester",
          "customer_admin_app_user_history": "Can download history of app users",
          "fingerprinter": "Fingerprinter",
          "bhvk_trial": "BHV-Knop trial user",
          "bhvk_admin": "BHV-Knop admin user"
        },
        "roles_title": "Roles",
        "invalid_phone": "Must be a valid phone number, starting with +",
        "invalid_sms": "Must be a valid sms number, starting with +",
        "title": "Users",
        "customer_user_relations": "Customer user relations",
        "update_success": "User updated",
        "admins_title": "Admins",
        "username_already_exists": "Username already exists, pick another to continue",
        "password_reset_to_base_sent": "Password successfully reset to default password",
        "page_info": "This page shows users which are able to login to the X-Guard Alarm Manager and/or the X-Guard Management Dashboard.",
        "access_to_dashboard": "Access to dashboard",
        "receives_alarms": "Receives alarms",
        "saving_successful": "User saved!",
        "sub_title": "This page shows all users in your BHV-Knop organisations. There are settings per user.",
        "available": "Receives alarms",
        "create_success": "User created",
        "has_access_to_dashboard": "Has access to the Management dashboard",
        "has_access_to_am": "Has access to the Alarm Manager",
        "add": "Add User",
        "manually": "Manually",
        "username_should_be_email": "Usernames should be based on the users email address. Not doing so  will make logging in for this user more difficult.",
        "delete_users": "Delete users",
        "delete_users_warning": "Are you sure you want to delete this user? |||| Are you sure you want to delete %{smart_count} users?",
        "users_with": {
          "multiple_connections": "Warning! There is a user connected to multiple customers. View them below. |||| Warning! There is are users connected to multiple customers. View them below."
        }
      }
    },
    "customers": {
      "text": {
        "title": "Customers",
        "alarmcenter_queueName": "Alarm center queue ( ask Jesse or Luuk)",
        "alarmcenter_title": "Alarm Centers",
        "update_success": "Customer updated",
        "uuid_validation": "Must be a valid UUID",
        "title_single": "Customer",
        "create_success": "Customer created!"
      },
      "fields": {
        "locale": "Locale",
        "responsePartner": "Is response partner",
        "responsePartners": "Response partners",
        "responsePartner_name": "Response partner",
        "alarmCenter_name": "Alarm center",
        "alarmcenter_relation": {
          "enabled": "Enabled"
        },
        "mergeConfig": {
          "enabled": "Enable alarm merging",
          "maxTimeBetweenAlarmsMs": "Max time between alarms ( seconds )",
          "retriggerAtsAfterMs": "Retrigger ATS after time ( seconds )",
          "onlyMergeWhenSame": "Only merge when one (or more) of these fields are the same",
          "use_default": "Use default merge config"
        },
        "alarmAgeLimit": "Alarms visible for ",
        "hasAlarmAgeLimit": "Has limited age of viewable alarms",
        "kiosk_config": {
          "what_groups": "Which groups of Assets would you like to show?",
          "what_labels": "What labels would you like to show?",
          "which_functions": "Which functions would you like to enable?",
          "show_alarms": "Show alarms",
          "manage_availability": "Manage availability",
          "screen_schedule": "Screen schedule",
          "incident_counter": "Incident counter",
          "front_page": "Front page",
          "anonymise_data": "Anonymise data",
          "incident_counter_count_from_date": "Count from date",
          "incident_counter_threshold": "Threshold (Hide counter in case of a recent incident)",
          "incident_counter_threshold_helper_text": "If no date is set, the days since the last incident are counted.",
          "manage_availability_pin": "Pin code",
          "manage_availability_app_hint": "Show hint: Install app",
          "manage_availability_show_search": "Always show search input",
          "manage_availability_show_label_filter": "Always show label filter",
          "alarm_audio": "Make noise by alarm",
          "alarm_flash": "Flash screen by alarm",
          "alarm_show_position_labels": "Show alarm position on map",
          "alarm_anonymous": "Anonymise data",
          "alarm_mode": "Alarm mode",
          "alarm_acknowledge": "Acknowledge alarm",
          "alarm_acknowledge_pin": "Code for acknowledging alarms",
          "asset_inside_zones": "Only show assets inside zones",
          "hardware": "Hardware",
          "screen_brightness": "Screen brightness",
          "countAssets": "Asset groups to include in counter",
          "flash": "Flash",
          "logo": "Visible logo",
          "schedule_entries": "When should the screen be turned on?",
          "anonymise_data_description": "Do you want to expose full names (and optionally photos) on the homepage of your kiosk? Only counters are shown on the anonymous version of the kiosk.",
          "what_groups_description": "Your organization might contain different groups of people. Select the relevant groups to display on this kiosk.",
          "logo_description": "If you do not upload a logo, the default X-Guard or BHV-Knop.nl logo is visible on the kiosk.",
          "manage_availability_description": "Do you want to use this kiosk to manage availability? This feature is only useful in kiosks with a touch screen or mouse attached.",
          "manage_availability_pin_description": "You can optionally protected editing availability with a PIN. Leave empty to require no PIN.",
          "show_alarms_description": "Do you want to show active alarms on the kiosk? This might help your staff to locate the location of the alarm and go there as quickly as possible.",
          "alarm_anonymous_description": "During an alarm situation we show who is called upon and who has accepted responding to the alarm. You can choose to anonymize this data.",
          "flash_description": "Flashing will occur during an alarm situation.",
          "alarm_acknowledge_description": "This feature will enable you to use this kiosk to quickly summarize what has happened and acknowledge the alarm. ",
          "alarm_acknowledge_pin_description": "Do you want to protect alarm acknowledgement with a PIN?",
          "incident_counter_description": "To encourage a safe working environment, you can show an incident counter on the homepage of your kiosk.",
          "incident_counter_count_from_date_description": "What date do you want the incident counter to count from? You can choose between the last alarm date and a fixed date you provide yourself.",
          "screen_schedule_description": "To protect the kiosk screen, you can enable the screen schedule. Outside of the defined schedule, the screen will turn off.",
          "schedule_entries_description": "Outside these times the screen will be black. Touching the screen will enable the kiosk, even outside of the schedule.",
          "manage_availability_pin_error": "Pin must be between 4 and 6 characters long.",
          "sound_description": "A loud sound will occur during an alarm situation. Please note that alarm sound feature is only reliably available on BHV-Knop.nl pre-programmed kiosks.",
          "sound": "Sound",
          "alarm_sound": "Choose your sound",
          "alarm_sound_description": "The sound you wish to play during an alarm situation is fully up to you. You can either choose for something that resembles a fire alarm or something very mellow.",
          "alarm_sound_volume": "Sound volume",
          "alarm_sound_volume_description": "We recommend a 100% volume, however when this is too loud for your liking you adjust it here.",
          "alarm_sound_stop_after_touch": "Stop sound on touch",
          "alarm_sound_stop_after_touch_description": "The kiosk will stop playing the alarm sound when you touch the screen.",
          "alarm_sound_stop_after_seconds": "Stop sound after...",
          "alarm_sound_stop_after_seconds_description": "After how many seconds do you want the alarm sound to stop? You also choose to have it repeat until the alarm has been acknowledged.",
          "count_label_separately": "Counter per group",
          "count_label_separately_description": "It is possible to count per selected group instead of showing the total count."
        },
        "disableAutomaticGenerationOfButtonZones": "Disable automatic generation of button zones"
      }
    },
    "tags": {
      "text": {
        "title": "Tag"
      }
    },
    "devices": {
      "text": {
        "title": "Devices",
        "types": {
          "lora": "Lora",
          "appDevice": "appDevice",
          "flic": "Flic",
          "generic": "Generic",
          "loraGateway": "Lora gateway"
        },
        "no_lora_config": "No specific lora config",
        "error_getting_devices": "Error getting devices",
        "result": "Result",
        "downLink_explanation": "Send a Hex payload for IoTracker devices connected to the KPN network",
        "buttons": "Buttons"
      },
      "fields": {
        "deviceId": "Device ID",
        "type": "Type",
        "loraConfig": {
          "type": "Lora device type",
          "fieldtest": "Fieldtest observation code",
          "beaconScan": "Beacon observation code",
          "gps": "GPS observation code",
          "preMandown": "Pre ManDown observation code",
          "mandown": "ManDown observation code",
          "alarmSeverity": "Alarm observation code",
          "alive": "Empty observation code",
          "floodDetected": "Flood detected observation code",
          "noFloodDetected": "No flood detected observation code",
          "type_help": "Make sure the correct device type is selected. If the device type is incorrect, the edited config will not be used.",
          "movement": "Movement observation code"
        },
        "battery": "Battery"
      },
      "actions": {
        "downLink": "Downlink"
      }
    },
    "flic-buttons": {
      "text": {
        "invalid_hub_id": "Invalid hub ID",
        "title": "Flic buttons",
        "found_button": "Button found!",
        "config": {
          "title": "Use the following configuration for the button:",
          "single_click_title": "Single click event",
          "single_click_description": "Update button meta-info",
          "double_click_title": "Double click event",
          "double_click_description": "Acknowledge (close) last made alarm by the same asset",
          "hold_title": "Hold event",
          "hold_description": "Create alarm according to configuration above",
          "hub_action": "Hub action",
          "internet_request": "Internet Request",
          "url": "URL",
          "method": "Method",
          "method_post": "POST",
          "description": "Description"
        },
        "waiting_for_press": "Waiting for button press",
        "test_mode_active_until": "Test mode active until",
        "activate_test_mode": "Activate test mode",
        "test_mode": "Test mode"
      },
      "fields": {
        "action": "Action",
        "serial": "Serial",
        "battery": "Battery",
        "deleted": "Deleted"
      }
    },
    "flic-hubs": {
      "text": {
        "title": "Flic hubs"
      },
      "fields": {
        "alarmLevel": "Alarm level",
        "xgacCustomerId": "Customer"
      },
      "go_to_buttons": "Buttons"
    },
    "observation-triggers": {
      "text": {
        "title": "Observation triggers",
        "general": "General",
        "action_config": "Action config",
        "add_action": "Add action",
        "add_eventcode": "Add event code",
        "filter": "Filter"
      },
      "fields": {
        "eventCodes": "Event codes",
        "description": "Description",
        "active_status": "Active status",
        "type": "Type",
        "config": "Configuration"
      }
    },
    "overlays": {
      "text": {
        "title": "Overlays",
        "overlay_not_found": "Overlay not found or not edited",
        "create": "Add new overlay",
        "delete_dialog_title": "Delete overlay",
        "delete_dialog_content": "Are you sure you want to delete this overlay?",
        "add_marker": "Add marker by clicking on the desired location on the map to continue creating.",
        "place_marker": "Place marker in center of the building"
      },
      "fields": {
        "image": "Overlay",
        "image_url": "Image url",
        "points": "Points"
      },
      "notifications": {
        "overlay_too_small": "Overlay too small, please edit to make larger."
      }
    },
    "qr-templates": {
      "text": {
        "title": "QR code templates",
        "mail_recipient_types": {
          "asset": "asset",
          "manual": "manual"
        },
        "generating_qr": "Generating QR, please wait...",
        "code_created": "Code created",
        "code_created_failed": "Code not created",
        "code_updated": "Code updated",
        "code_updated_ failed": "Code not updated",
        "code_deleted": "Code deleted",
        "code_deleted_failed": "Code not deleted",
        "options": "Options",
        "default_qr": "Default QR",
        "brochure_nl": "Brochure NL",
        "brochure_en": "Brochure EN",
        "field": "Field",
        "value": "Sjabloon label",
        "locked": "Locked",
        "external_id": "External ID",
        "first_name": "First name",
        "last_name": "Last name",
        "user_email": "User email",
        "phone_prefix": "Phone country prefix",
        "phone_number": "Phone number",
        "available": "Available",
        "limit": "Limit",
        "save_first": "Save first before adding codes",
        "codes": "Codes",
        "actions_save_first": "To add actions, save first",
        "registering_title": "Registering... Please wait",
        "uploading": "File uploading, please wait...",
        "download_qr": "Download or preview QR",
        "preview": "View",
        "single_title": "QR template",
        "sends_email": "Sends email"
      },
      "fields": {
        "group_list": "Group list",
        "recipient_type": "Recipient type",
        "template_label": "Template label",
        "template_value": "Template value",
        "recipient_list": "Recipient list",
        "template_updated": "Template updated",
        "template_update_failed": "Template not updated",
        "pages": "Pages",
        "visible": "Visible",
        "actions": "Actions"
      }
    },
    "service-accounts": {
      "text": {
        "title": "Service Accounts",
        "username_validation": "Username of service account must start with \"sa-\""
      }
    },
    "static-responders": {
      "text": {
        "title": "Static responders",
        "days": {
          "monday": "Monday",
          "tuesday": "Tuesday",
          "wednesday": "Wednesday",
          "thursday": "Thursday",
          "friday": "Friday",
          "saturday": "Saturday",
          "sunday": "Sunday",
          "all": "All days",
          "weekdays": "Working days",
          "weekends": "Weekends"
        },
        "general": "General",
        "contact_options": "Contact options",
        "availability": "Availability",
        "professional_response": "Professional response"
      },
      "fields": {
        "schedule_entry_add": "Add schedule entry",
        "contact": {
          "camera": "Camera"
        },
        "externalId": " External ID",
        "schedule": {
          "name": "Schedule",
          "entries": "Schedule entries"
        },
        "days_of_week": "Days of week",
        "time_start": "Time start",
        "time_end": "Time end",
        "integrations": {
          "sequrix": {
            "enabled": "Sequrix",
            "objectCode": "Object code"
          }
        }
      }
    },
    "webhooks": {
      "text": {
        "title": "Webhooks",
        "entity": "Entity",
        "filter_explanation": "Webhook filter (Mongo query format)",
        "delivery": "Delivery",
        "enabling": "Enabling",
        "output_preview": "Output preview"
      },
      "fields": {
        "enableWebhook": "Webhook enabled",
        "webhookQuery": {
          "operation": "Operation",
          "entityTypes": "Entity types",
          "filter": "Filter"
        },
        "deliveryConfig": {
          "method": "Method",
          "url": "Url",
          "headers": "Headers"
        },
        "deliveryStats": {
          "numDeliverySucceeded": "Number of successful deliveries",
          "numDeliveryFailed": "Number of failed deliveries",
          "lastDeliveryDate": "Last successful delivery"
        }
      }
    },
    "zones": {
      "text": {
        "zoneTypes": {
          "default": "Default",
          "response_area": "Response area",
          "police_department": "Police department",
          "fingerprinted": "Fingerprinted",
          "assetMonitoredArea": "Asset monitored area"
        },
        "title": "Zones",
        "polygon_should_smaller": "The polygon area should be smaller than a single hemisphere",
        "one_figure_per_zone": "You can only add one figure per zone.",
        "invalid_zone": "Zone is not valid: More or less than one figure.",
        "title_single": "Zone",
        "invalid_zone_intersection": "Zone is not valid: Has self intersection.",
        "syncToAms_explanation": "When turned on, this zone is used as an AMS zone, making it possible for devices to trigger different actions when entering/exiting the zone.\nCurrently only relevant for switching BHV-Knop.nl availability. This will be expanded later.",
        "beacons_in_zone": "Linked beacons",
        "assets_inside": "There is currently %{smart_count} person in this zone |||| There are currently %{smart_count} people in this zone",
        "updated": "Zone updated!",
        "assets_inside_dialog_title": "Persons inside this zone",
        "zone_list": "Zone list",
        "manage": "Manage zones",
        "created": "Zone created!",
        "delete": "Delete zone",
        "deleted": "Zone deleted",
        "create_mode_dialog_title": "Do you wish to create this zone as a point on the map or as an area?",
        "area": "Area",
        "area_subtext": "Draw a closed area on the map",
        "marker": "Point on the map",
        "marker_subtext": "One point on the map.\nSuitable for localisation with X-Guard Beacons",
        "edit_warning": "You're currently editing a zone. Save these changes or cancel.",
        "still_editing_warning": "You're currently editing a zone. Save these changes or cancel.",
        "assets_inside_empty": "There are currently no people in this zone",
        "create_dialog_where_title": "Where do you want to place this zone?",
        "create_dialog_where_map": "I will manually pick the position on the map",
        "create_dialog_where_input": "On an address",
        "delete_disabled_ams_or_beacons": "This zone is used in advanced internal configuration and should not be deleted.",
        "delete_disabled_links_or_triggers": "This zone is used in advanced internal configuration and should not be deleted.",
        "zones_with_similar_prints": "There is %{smart_count} pair of zones with similar data, click to see more |||| There are %{smart_count} pairs of zones with similar data. Click to see more",
        "customers_with_same_codes": "Beacon codes found in fingerprints at different customer environments. click to see more",
        "zones_with_similar_prints_title": "Zones with similar prints",
        "customers_with_same_codes_title": "Customers with same codes in print",
        "not_fingerprinted_zones": "Zones with no or limited fingerprints",
        "zones_with_bad_prints": "There is 1 zone with fingerprints that do not have enough beacons to be of quality. Click to see more. |||| There are %{smart_count} zones with fingerprints that do not have enough beacons to be of quality. Click to see more.",
        "zones_with_bad_prints_title": "Zones with bad prints",
        "beacon_calc": {
          "open_button": "IPS quote",
          "dialog_title": "Calculated rooms",
          "table": {
            "beacons": "beacons",
            "room_size": "Size",
            "room_number": "Number",
            "total_square_meters": "Total m²",
            "beacon_number": "Estimated number of beacons",
            "fingerprint_time": "Fingerprinting",
            "beacon_place_time": "Mounting beacons",
            "total_time": "Total",
            "small": "Small",
            "medium": "Medium",
            "large": "Large"
          }
        },
        "add_screen_subtext": "Generate a screen url",
        "no_zones": "Create a zone first",
        "delete_disabled_kiosks": "This zone is used in a kiosk and should not be deleted.",
        "filter_in_bounds": "Filter out of map",
        "update_dialog_title": "Do you want to update this zone?",
        "update_dialog_content": "This zone is fingerprinted. Updating this zone can make the fingerprints inaccurate. Do you want to update this zone?"
      },
      "filters": {
        "only_show": "Only show"
      },
      "fields": {
        "type": "Type",
        "description": "Description",
        "number_of_responders": "Number of static responders",
        "responsePriority": "Response priority",
        "number_of_beacons": "Number of Beacons",
        "number_of_assets": "Number of assets inside this zone",
        "syncToAms": "Sync to AMS",
        "alarm_position": "Alarm position",
        "fingerprintCount": "Fingerprint count",
        "has_address_position": "If enabled, the entered address will be used within the entire marked zone."
      }
    },
    "contact-lists": {
      "text": {
        "title": "Contact lists",
        "contacts_number": "Number of contacts"
      },
      "fields": {
        "contacts": "Contacts"
      }
    },
    "report-triggers": {
      "text": {
        "title": "Report triggers",
        "granularity_types": {
          "minute": "minute(s)",
          "hour": "hour(s)",
          "day": "day(s)",
          "week": "Week(s)",
          "month": "Month(s)",
          "quarter": "Quarter(s)",
          "year": "Year(s)"
        },
        "config_types": {
          "unhealthyBeacons": "Unhealthy beacons",
          "observation": "Observation count",
          "alarms": "Alarms",
          "newAlarm": "New Alarm",
          "inactivityMonitor": "Inactivity monitor",
          "appUsage": "App usage",
          "assetHistory": "Asset history",
          "alarmCenterStatus": "Alarmcenter status",
          "zoneOverview": "Zone overview",
          "subtext": {
            "alarms": "The alarm report sends a report containing an overview of the alarms made since the last report. When enabled, the report is sent the next monday, and %{interval} after that.",
            "appUsage": "The app usage report sends a report containing an overview of the usage of the app per user in the last month.  When enabled, the report is sent at the start of the month, each month.",
            "inactivityMonitor": "The inactivity monitor report triggers when a button does not appear online for at least 24 hours. This indicates that the button is malfunctioning, either due to low battery, it being out of range of the gateway, or a physical defect. The sent email will indicate what the follow-up steps are.",
            "unhealthyBeacons": "The unhealthy beacons report contains beacons that have not been seen recently, or have low remaining battery. It is sent %{interval}.",
            "alarmCenterStatus": "The alarm center status report contains information about the number  of alarms escalated to the alarm center.",
            "newAlarm": "The new alarm report sends when a new alarm is created. This is not sent for test alarms."
          }
        },
        "config": {
          "excel_types": {
            "default": "Default",
            "smc": "SMC"
          }
        },
        "channel_types": {
          "contact_list": "Contact list"
        },
        "go_contactlist": "Click to go to contact list(s).",
        "licence_modes": {
          "hourlyUsage": "Hourly usage",
          "license": "Licence"
        },
        "filter_tags": {
          "active": "Active",
          "inactive": "Inactive",
          "new": "New",
          "forgotten": "Forgotten"
        },
        "invalid_email": "One or more invalid email(s)",
        "not-exists": "Trigger does not exist anymore",
        "theme": {
          "default": "Default (X-Guard)",
          "bhvk": "BHV-Knop.nl"
        },
        "send-for-current-month": "Also send for current month",
        "send-current-month-unavailable": "This report can no longer be sent for this month.",
        "unsubscribedEmails_helper": "Addresses in this field will not be sent this report."
      },
      "fields": {
        "config": {
          "type": "Type",
          "last_seen_threshold": "Last seen threshold",
          "excelFormat": "Excel format",
          "alarmTypes": "Alarm types",
          "timeSeries_title": "Time series",
          "severities": "Alarm severities",
          "timeSeries": {
            "granularity": "Granularity",
            "timeframe": "Timeframe"
          },
          "app_mode": "App mode",
          "timeout": "Timeout",
          "floorTo": "Floor to",
          "agOptions": {
            "includeRaw": "Include raw",
            "includeSimple": "Include simple"
          },
          "excludeCustomers": "Exclude customers"
        },
        "enabled": "Enabled",
        "lastRunAt": "Last run at",
        "nextRunAt": "Next run at",
        "runInterval_title": "Run interval",
        "channels": "Channels",
        "channels_type": "Type",
        "channels_address": "Email addresses",
        "time_granularity": {
          "value": "Amount",
          "type": "Time"
        },
        "channels_number": "Phone numbers",
        "lastAttemptedAt": "Last attempted at",
        "unsubscribedEmails": "Unsubscribed email addresses"
      }
    },
    "unhealthyBeacons": {
      "text": {
        "inactive": {
          "label": "Inactive beacons"
        },
        "lowBattery": {
          "label": "Low battery beacons"
        },
        "noneFound": "No beacons found in this customer",
        "noAttachments": "No attachments found."
      }
    },
    "lite-templates": {
      "name": "QR Codes"
    },
    "app_users": {
      "text": {
        "groups_and_config_explanation": "Pick groups and configuration below. If desired, a QR template can be picked as base. The chosen groups and configuration can be set on all selected App users. For the groups and configuration, a choice can be made between replacing existing values, adding to the existing values, or skipping the groups or configuration. When skipping is selected, the values will not be edited."
      }
    },
    "domainlinks": {
      "title": "Domain restrictions"
    },
    "domainlink": {
      "fields": {
        "domain": "Domain"
      }
    },
    "buttons": {
      "text": {
        "manage_buttons": "Manage buttons",
        "search": "Search devices",
        "show_all": "Show all buttons",
        "coordinates_small": " (drag the point on the map)",
        "gateway_map_disclaimer": "The circle around a base station is just an indication of range.",
        "mass_move_title": "Move multiple buttons",
        "move_confirm_button": "Move",
        "mass_move_explanation": "Choose the address",
        "button_number_tooltip": "The button-number is printed on a sticker on the bottom",
        "gateway_number_tooltip": "The number of the gateway is printed on the back of the device",
        "test_button_title": "You will receive a notification",
        "test_button_content": "This notification will go to you alone, not all available BHV'ers. Do you want to test with all available personnel, actually push the button.",
        "test_sent": "Test sent, you will receive this alarm via a call",
        "test_disabled": "Test disabled because the logged in user has no valid method to receive the alarm",
        "print_all_labels": "Print all labels",
        "print_all_labels_confirm": "Are you sure you want to print all labels?",
        "sufficient_battery": "Sufficient battery",
        "show_buttons": "Show buttons",
        "building_dialog": {
          "0": {
            "title": "Add a building",
            "explanation": "Based on the address found, we will automatically try to load your building with the available data from the cadastral map.",
            "input_label": "Search for the address...",
            "input_upper_label": "Search for your full address"
          },
          "1": {
            "title": "Found building",
            "explanation": "We found a building. is this your building? If this is not correct, select the correct building on the map and continue.",
            "surface_area": "Surface area %{area} m²",
            "next_button": "Continue with the selected building |||| Continue with the selected buildings",
            "building_count": "%{smart_count} building |||| %{smart_count} buildings"
          },
          "2": {
            "title": "Building %{building} of %{max}",
            "how_many_floors": "How many floors does the building have?",
            "floors": "floors",
            "next_button": "View the proposal",
            "next_builing_button": "Next building",
            "office_or_factory": "What describes this building the most accurately?",
            "office": "Office",
            "factory": "Factory or hall",
            "button_distance_title": "Maximum walking distance to a button",
            "button_distance_sub_title": "We advice a distance of 30 meters.",
            "button_distance_button": "%{distance} meter",
            "recommended": "Recommended"
          },
          "3": {
            "title": "Our proposal",
            "next_button": "Add the products",
            "view_price": "Show me the cost"
          },
          "no_streetnumber": "No street number found for chosen address",
          "change_later": "You can easily change this later",
          "building": "Building",
          "not_in_countries": "Please select an adress in The Netherlands or Flanders.",
          "max_buildings": "It is recommended not to add more than %{count} buildings at a time",
          "no_buildings_found": "No buildings found at supplied address"
        },
        "map_default": "Map",
        "map_satellite": "Satellite",
        "map_center": "Center",
        "floors_chosen": "Floors selected",
        "all_floors": "All floors",
        "actions_with_selected": "Actions with selected",
        "mass_move": "Move",
        "add_gateway": "Add gateway",
        "add_button": "Add button",
        "create_dialog_title_gateway": "Where do you want to place this gateway?",
        "create_dialog_title_button": "Where do you want to place this button?",
        "how_many_gateways": "How many gateways would you like to add?",
        "how_many_buttons": "How many buttons would you like to add?",
        "no_concept_devices": "No unpaid emergency buttons or gateways found within your selected devices",
        "unpaid_devices": "You have unpaid products",
        "delete_unpaid": "Delete these products",
        "deleted_unpaid_devices": "Unpaid devices deleted",
        "delete_are_you_sure": "Are you sure you want to delete these unpaid devices?",
        "delete_add_again": "These devices can be easily added again.",
        "deleted_unpaid_devices_error": "An error occured while deleting the selected devices.",
        "not_ordered": "Not ordered yet",
        "place_button": "Place button(s)",
        "place_gateway": "Place gateway(s)",
        "stop_placing": "Stop placing devices",
        "status": {
          "online": "This device is online",
          "offline": "This device is offline",
          "pending": "The status of this device is being retrieved",
          "empty": "The battery of this device is (almost) empty",
          "lowRSSI": "Too far away",
          "highNoise": "Radio signal is noisy",
          "nobody_why": "Nobody contacted, read why here"
        },
        "deviceType": "Type of device",
        "remove_filters": "Remove filters",
        "save_filters": "Save filters",
        "active_status_label": "Active status",
        "active_status": {
          "active": "Ordered and active devices",
          "unpaid": "Unpaid devices",
          "demo": "Demo devices"
        },
        "all_devices": "All types of devices",
        "devices_chosen": "Device types chosen",
        "all_active_status": "All statuses",
        "active_status_chosen": "Status chosen",
        "building_distance_warning": "The selected buildings are far apart. Check whether these buildings belong to \"%{address}\". Adding another address is possible by adding another building when you are done with the current one.",
        "create_alarm_uncontacted": " %{smart_count} person not previously contacted |||| %{smart_count} previously uncontacted people",
        "invalid_address": "We could not find this address in the Netherlands. You can add addresses outside the Netherlands by placing a separate emergency response button on your building.",
        "locked": "Locked",
        "unlocked": "Unlocked",
        "create_alarm_confirm_title": "Are you sure you want to create an alarm for this button?",
        "create_alarm_current_address": "Current address: "
      },
      "fields": {
        "address": "Textual adress"
      },
      "automove_title": "Automatically move buttons"
    },
    "notifications": {
      "move_multiple_no_location": "Please select a location before clicking move",
      "moved_multiple": "Buttons and/or gateways moved.",
      "moved_multiple_error": "Error while moving, one or more might not be moved."
    },
    "report_triggers": {
      "text": {
        "to_whom": "To whom?",
        "specific_fields": "Report type specific fields"
      },
      "what_report": "What report?"
    },
    "roles": {
      "title": "Roles"
    },
    "reports": {
      "title": "Reports",
      "text": {
        "attached_email": "Generated email",
        "show_email": "Show email",
        "sub_title": "The various reports can be found on this page. Here you can also switch this on/off and determine to whom the report will be sent. Are you missing a report? Contact us.",
        "recipients": "Recipients",
        "no_recipients": "This report will not send to any recipients. This is not correct configuration.",
        "confirm_dialog_content": "Are you sure you want to disable this report?",
        "settings": "Report settings",
        "hour": "%{smart_count} hour |||| %{smart_count} hours",
        "day": "%{smart_count} day |||| %{smart_count} days",
        "week": "%{smart_count} week |||| %{smart_count} weeks",
        "zones_no_data": "On this page you can see who was present in your zones in the past.\n\nThis way you can ensure that there were enough people present at the time you want.",
        "zones_settings_title": "Live availability report",
        "zones_settings_explain": "This report shows live the current and previous occupancy in the selected Zones.",
        "zones_settings": {
          "zone_helpertext": "Choose which Zones you want to display the graphs for",
          "period_helpertext": "Choose how long you want to look back. With a longer period, the data displayed becomes less accurate.",
          "period": "Period",
          "min_occupancy_helpertext": "Optionally choose how many people you would like to be present. This is, for example, the desired number of emergency response personnel.",
          "includeGroups_helpertext": "Choose which user groups can count as occupancy.",
          "inactivityThreshold": "Require activity in the app",
          "occupancy_title": "How many people would you like to be present?",
          "schedule": "Do you want to set a schedule for minimum occupancy?",
          "zone_title": "Which zone do you want to monitor?",
          "enabled": "Notify by email in case of understaffing",
          "schedule_warning": "You can set different minimum occupancy for different schedules",
          "asset_groups": "What groups would you like to monitor?"
        },
        "zones_no_groups": "There are no zones to monitor. You can add zones via the Zones page.",
        "month": "%{smart_count} month |||| %{smart_count} months",
        "subtext_week": "every week |||| every %{smart_count} weeks",
        "subtext_month": "every month |||| every %{smart_count} months",
        "subtext_day": "every day |||| every %{smart_count} days",
        "subtext_hour": "every hour |||| every %{smart_count} hours",
        "subtext_quarter": "every quarter |||| every %{smart_count} quarters",
        "subtext_year": "every year |||| every %{smart_count} years",
        "add_zone": "Monitor new zone",
        "zone_monitor_added": "Monitoring started",
        "zone_monitor_updated": "Monitoring updated",
        "sends_email": "Email Alerts",
        "bhvk_title": "Availability in the last 7 days",
        "zone_monitor_loading_secondary": "Loading availability, please wait..."
      },
      "fields": {
        "attachments": "Attachments",
        "last_attachments": "Show last email",
        "minOccupancy": "Desired occupancy",
        "occupancy": "Occupancy",
        "enable_warnings": "Enable warnings"
      },
      "title_single": "Report"
    },
    "report-trigger-runs": {
      "title": "Reports",
      "empty": "No reports yet"
    },
    "kiosk-users": {
      "title": "Kiosks",
      "default_config": "Uses default configuration",
      "text": {
        "config_not_set": "Configuration not set",
        "created": "Screen user created",
        "set_zone": "Error: set a zone"
      },
      "fields": {
        "kioskConfig": {
          "managed": "Managed"
        },
        "fully_android_id": "Fully Kiosk Android ID",
        "fully_device_id": "Fully Kiosk Device ID",
        "managed": "X-Guard Managed"
      },
      "url_dialog_title": "Your Kiosk URL",
      "url_dialog_privacy": "I understand that this URL gives access to potentially confidential information from my organisation.",
      "url_dialog_show_once": "I understand that this URL is only shown once. It will not be shown again, and if I lose it a new URL needs to be generated.",
      "url_dialog_use_once": "I understand that this URL can only be used on one display. If you have more displays, create more screens in this dashboard.",
      "kiosk_number": "Kiosk number",
      "url_dialog_agree": "I understand that doing this will regenerate the URL. The old, existing URL will no longer work."
    },
    "qr-code": {
      "text": {
        "single": "QR-code"
      }
    },
    "fingerprints": {
      "text": {
        "progress_zones": "Fingerprinted zones",
        "progress_devices_in_fingerprint": "Devices in fingerprints",
        "progress_beacons_in_fingerprint": "Number of beacons in fingerprints",
        "is_similar_to": "Is similar to",
        "similar_scans": "Similar scans",
        "other_customer": "Other customer",
        "create_beacons": "Create beacons for codes in fingerprints",
        "missing_codes": "Codes of missing beacons",
        "create_beacons_button": "Create beacons",
        "beacons_created": "%{smart_count} beacon created! |||| %{smart_count} beacons created!",
        "beacons_not_created": "Beacons could not be created",
        "check_beacons": "Check if beacons need creating",
        "check_progress": "Check fingerprint progress and health",
        "disable_statistics": "Disable progress and health"
      },
      "fields": {
        "userId": "Fingerprinted by"
      }
    },
    "kiosks": {
      "text": {
        "manage": "Manage kiosks",
        "add_kiosk": "Add kiosk",
        "add_screen": "Add screen",
        "general_settings_dialog": {
          "title": "Default kiosk settings",
          "switch_availability": "Availability"
        },
        "kioskConfig": {
          "homepage": {
            "incidentCounter": {
              "last_alarm": "From the last alarm"
            }
          }
        },
        "general_settings_updated": "Kiosk settings updated",
        "menu_title": "Kiosks",
        "create_dialog_title": "Create Kiosk",
        "create_dialog_title_screen": "Create screen-url",
        "zone_not_found": "Zone not found, not creating...",
        "kiosk_deleted": "Kiosk deleted",
        "kiosk_delete_title": "Delete kiosk \"%{name}\"",
        "kiosk_created": "Kiosk created!",
        "kiosk_created_screen": "Screen created!",
        "zone_warning": "This kiosk will be moved to the center of the selected zone upon saving.",
        "admin_actions": {
          "button": "Admin actions"
        },
        "kiosk_moved": "Kiosk moved!",
        "kiosk_move_title": "Move kiosk \"%{name}\"",
        "kiosk_regenerated": "Kiosk credentials regenerated. Old url no longer usable",
        "kiosk_regenerate_button": "Regenerate credentials",
        "kiosk_regenerate_title": "Regenerate URL for \"%{name}\".",
        "customer_admin_create_dialog_content": "Kiosks are a great way to both show and manage who is available in your organisation.\n\n## Presence detection with the BHV-Knop.nl Kiosk\nKiosks are mainly aimed at in-company emergency teams.\n\n[Read more about presence detection](https://bhv-knop.nl/en/hoe-werkt-het/aanwezigheidsdetectie/ (English))\n\n## Hardware\nWe offer a fully managed solution with our handy touch screens. Mainly aimed at our BHV-Knop.nl customers.\n\nContact your account manager for more information.\n\n![Kiosk example](https://bhv-knop.nl/wp-content/uploads/2024/02/kiosks-663x1024.png)\n\n## Display a kiosk on your own existing screens\nWe offer the possibility to show the kiosk on your existing company screens. You will receive a webbrowser URL to display on the screen.\n\nYou do not currently own this license. Contact your account manager for more information.",
        "general_settings": {
          "overwrite": "Overwrite default configuration"
        },
        "default_config": "Configure",
        "kiosk_preview": "Kiosk preview",
        "create_mode_dialog_title": "Do you want to create a new kiosk or screen?",
        "add_kiosk_subtext": "Add a managed kiosk",
        "status": {
          "online": "Kiosk seen in the last 30 minutes.",
          "offline": "Kiosk not seen in the last 30 minutes."
        },
        "none_yet": "No kiosks yet",
        "edit_single_confirm_content": "Are you sure you want to edit a single kiosk? You can also edit the general kiosk settings for this account.",
        "edit_single_confirm_title": "Are you sure you want to edit a single kiosk?",
        "edit_single_confirm_cancel": "No, edit the general settings for kiosks",
        "edit_single_confirm_confirm": "Yes, continue editing",
        "publish": "Publish to %{smart_count} kiosk |||| Publish to %{smart_count} kiosks",
        "defaults": "Kiosk defaults",
        "defaults_updated": "Kiosk defaults updated",
        "defaults_update_error": "Error while updating defaults. One or more keys are not updated"
      }
    },
    "groups": {
      "text": {
        "bhvk-explanation": "Groups are used to separate users in your organization. For example, you can create a group with BHV'ers and a group 'Head BHV' or 'AIT (Aggression Intervention Team'). BHV-Knop.nl can work with you to set up a distinction per group in how and when the alarm comes in."
      }
    },
    "auditlogs": {
      "text": {
        "subject": "Subject",
        "user": "User",
        "operation": "Action",
        "title": "Audit logs",
        "resource_types": "Resource types",
        "expose_users": "Expose users"
      }
    },
    "customer-alarm-center-relations": {
      "title": "Alarm center relations",
      "fields": {
        "enabled": "Enabled",
        "priority": "Priority"
      },
      "name": "Alarm center relation"
    }
  },
  "general": {
    "text": {
      "open_in_am": "Alarm Manager",
      "app_mode": {
        "on": "On",
        "off": "Off",
        "risk": "Risk"
      },
      "profile": "Profile",
      "true": "true",
      "false": "false",
      "move_users_failed": "Not all users were moved succesfully",
      "move_users_success": "All users were moved succesfully",
      "move_users_title": "Move users",
      "move_users_explanation": "Move users to a different customer",
      "move": "Move",
      "close": "Close",
      "deleted": "deleted",
      "none": "none",
      "all": "All",
      "yes": "Yes",
      "no": "No",
      "invalid": "Invalid",
      "saved": "Saved",
      "updated": "updated",
      "stability": {
        "unstable": "Unstable - Do not use this page without proper training",
        "beta": "Beta - You can freely use this page but you might encounter problems",
        "stable": "Stable - This page has been tested and should work as expected",
        "annotations_title": "This page has the following annotations:"
      },
      "my_user_settings": "My user settings",
      "never": "never",
      "actions": "Actions",
      "contact_info": "Contact information",
      "hover_to_view": "hover to view",
      "export_selection": "Export selection",
      "export_started": "Started export, please wait....",
      "exporting": "Exporting...",
      "export_all": "Export all",
      "not_acknowledged": "Not acknowledged",
      "show_persons": "Show %{length} persons",
      "copied": "Copied to clipboard",
      "copy": "Copy",
      "proceed": "Proceed",
      "mobileWarning": "Unfortunately this dashboard has not been optimised for mobile devices. We are working to improve this. In the mean time we recommend using a PC or tablet to open this page.",
      "appUserWarning": "This dashboard is only meant for management users of your organisation.\n\nYou are currently logged in with your X-Guard Alarm App account. You can not view the management dashboard as an app user.\nIf you are able to view our other dashboard, a button to go there will appear in the bottom corner.",
      "message": "Message",
      "replace": "Replace",
      "append": "Append",
      "skip": "Keep current value",
      "no_template_selected": "No template selected",
      "no_values": "No values",
      "no_time_yet": "No time (yet)",
      "alarm": "Alarm",
      "more_settings": "More settings",
      "order": "Order",
      "orders": "Orders",
      "help": "Help",
      "invoices": "Invoices",
      "none_yet": "No %{name} yet, please create one",
      "settings": "settings",
      "install_app": "Install the app",
      "when": "When?",
      "how_often": "How often?",
      "gateway": "Gateway",
      "button": "Button",
      "appUserWarning_bhvk": "This dashboard is only meant for who have been granted access by an administrator.\n\nYou are currently logged in with an account without access to this dashboard. You can not view the dashboard with this account.\nIf you are able to view our other dashboard, a button to go there will appear in the bottom corner.",
      "number_unknown": "number unknown",
      "change_username_modal_title": "Welcome in the BHV-Knop.nl Dashboard",
      "current_username": "We automatically created a username for you, you can change it here",
      "continue": "continue",
      "current": "Current",
      "not_in": "Not in",
      "test": "Test",
      "send": "Send",
      "persons": "Persons",
      "current_user": "Current user",
      "this_dashboard": "This dashboard",
      "enabled": "Enabled",
      "disabled": "Disabled",
      "or": "or",
      "cannot_save": "Cannot save",
      "no_changes": "No changes",
      "theme": "Theme",
      "export_select_columns": "Select columns",
      "toggle_all": "Toggle all",
      "logo": "Logo URL",
      "filter": "Filter",
      "location": "Location",
      "search_google_maps": "Search via Google Maps",
      "saving": "Saving...",
      "order_extra": "Order extra",
      "solve_issues": "Solve issues",
      "welcome": "Welcome",
      "gateways": "Gateways",
      "order_singular": "Order",
      "online": "Online",
      "last_days": "last %{count} days",
      "incorrect_phonenumber": "Incorrect phonenumber, try again",
      "free_trial": "Free trial",
      "licence_active": "Licence active",
      "simulate_customer_admin": "Simulate customer admin",
      "exit_role_simulation": "Exit role simulation",
      "upload_file": "Upload file",
      "emergency_button": "Emergency button",
      "select_all": "Select all",
      "deselect_all": "Deselect all",
      "order_now": "Order now",
      "emergency_buttons": "Emergency buttons",
      "filtered": "Filtered",
      "go_to_management": "Go to Management Dashboard",
      "go_to_bhvk": "Go to Beheer.BHV-Knop",
      "form": {
        "inherit": "Inherit",
        "override": "Override"
      },
      "map_floors": "Floor",
      "device": "Device",
      "search_location": "Search location",
      "availability": "Availability",
      "from": "From",
      "until": "Until",
      "optional": "optional",
      "general": "General",
      "invalid_time": "Must be a valid time",
      "time_end_before_start": "End time should be after start time",
      "timestamp": "Timestamp",
      "history": "History",
      "seconds": "seconds",
      "person": "person |||| persons",
      "customer_not_found": "No customer found with given ID",
      "present": "Present"
    },
    "fields": {
      "createdAt": "Created at",
      "updatedAt": "Updated at",
      "position": {
        "address": {
          "countrycode": "Country code",
          "state": "State",
          "city": "City",
          "streetName": "Street name",
          "streetNumber": "Street number",
          "floor": "Floor",
          "formattedAddress": "Saved formatted adress",
          "name": "Address"
        },
        "latitude": "Latitude",
        "longitude": "Longitude",
        "coordinates": "Coordinates",
        "name": "Position",
        "properties": {
          "dateTime": "Position timestamp"
        }
      },
      "name": "Name",
      "description": "Description",
      "person_or_object": "Person or object",
      "madeBy": "Made by",
      "number": "Number",
      "registration_profile": "Registration profile"
    },
    "errors": {
      "username_too_short": "Username should at least be three characters long."
    }
  },
  "pages": {
    "live_map": {
      "text": {
        "no_assets_in_customer": "No persons or objects found",
        "position_is": "Position is ",
        "indoors": "indoors ",
        "outdoors": "outdoors ",
        "at": " at",
        "accuracy": "Accuracy is %{accuracy} meters",
        "last_position": "Last position was %{time} ",
        "person_available": "This person is %{available} available",
        "not": "not",
        "app_mode": "App mode is"
      }
    },
    "userReceiverInitialisation": {
      "title": "How do you want to receive Alarms?"
    }
  },
  "menu": {
    "items": {
      "live": "Live",
      "map": "Map",
      "my_organisation": "My organisation",
      "alarms": "Alarms",
      "create_alarm": "Create alarm",
      "access": "Access",
      "root_admins": "Root admins",
      "problematic_alarms": "Problematic alarms",
      "alarm_app": "X-Guard Alarm App",
      "groups": "Groups",
      "qr_codes": "QR Codes",
      "people_assets": "People & Assets",
      "assets": "Assets",
      "tags": "Tags",
      "flic": "Flic",
      "alarm_tasks": "Alarm tasks",
      "resources": "Resources",
      "alarm_handling": "Alarm handling",
      "reports": "Reports",
      "manage": "Manage",
      "users": "Admins",
      "management_users": "Users",
      "dashboard": "Dashboard",
      "maps": "Maps",
      "response": "Check response"
    },
    "text": {
      "brought_by": "Brought to you by"
    }
  },
  "home": {
    "text": {
      "title": "Welcome to the X-Guard Management dashboard",
      "description": "You can use this dashboard to view your current X-Guard enviroment. Unfortunately it's not possible yet to edit the resources you can see. Editing and creation possibilities will be added later.",
      "block_one": "### Welcome to the X-Guard Management dashboard\n\nYou can use this dashboard to manage and view your current X-Guard environment.",
      "block_two": "#### Update 1.21\nThe Availability page is now available for you to monitor your zones. Instantly view the number of people available in your zones to respond to alarms.\n\nAdditionally, you can choose to receive email warnings when the set threshold is not achieved.\n\n#### Update 1.20\nFrom this version it is possible to add floor plans. Maps are visible on all pages with a map in this dashboard and on BHV-Knop.nl kiosk screens in your organization. Floor plans are also used by dispatchers to locate more accurately within a building.\n\nIn addition, various reports are now added under the heading 'Live' with which you can immediately monitor the current status of your organization.\n\n#### Update 1.19\nThis version was a larger stability update.\n\n#### Update 1.18\nIt is now possible to manage reporting directly from this dashboard. Adjust whether reports are sent and to whom.\n\n#### Update 1.17\n\nStarting with version 1.17, you can now access the Zone configuration page. This page gives you insight into how certain zones are defined within the X-Guard system.\n\nYou can edit, add and delete zones. However, adding advanced configuration to a zone is still done by the X-Guard support team. For your security, it is not possible to delete a zone that has an advanced configuration associated with it.\n\nNot sure where to start? Please contact your X-Guard Account Manager for a brief explanation.\n\n\n#### Update 1.16\n\nIt is now possible to **add, remove and edit** app users directly from the dashboard. You can also **manage groups** and **manage users**.\n\nApp users are users who can use the X-Guard Alarm app. They cannot use the Management Dashboard and Alarm Manager.\n\nUsers have access to the Management Dashboard and the Alarm Manager and can perform administrative tasks.",
      "block_three": "#### Feedback\n\nDo you encounter something in this Dashboard which is not quite right? Or do do you have a new feature request?\n\n**The bottom-right corner of all pages** shows a convienent **Feedback button**. Click this button to directly send the X-Guard Development team your feedback."
    }
  },
  "ra": {
    "message": {
      "clear_array_input": "Are you sure you want to clear the whole list?"
    },
    "input": {
      "image": {
        "remove_current": "Remove current"
      }
    },
    "action": {
      "preview": "Preview",
      "publish": "Publish"
    },
    "notification": {
      "name_too_long": "Name too long"
    }
  },
  " resources": {
    "users": {
      "text": {
        "password_reset_to_base_failed": "Resetting password to default password failed."
      }
    },
    "report-triggers": {
      "text": {
        "config_types": {
          "unhealthyBeacons": "Unhealthy Beacons"
        }
      }
    }
  },
  "qr-templates": {
    "text": {
      "page_info": "You use a QR code to register in your organization directly from the X-Guard Alarm app.\n\nShare the QR codes with people who need to log in within the app."
    }
  },
  "resource": {
    "customers": {
      "fields": {
        "hasKioskConfig": "Has Kiosk config"
      }
    },
    "buttons": {
      "automove_subtitle": "We can automatically move this button to the new address. Do you want to move this button?",
      "automove_no": "No, keep current position",
      "automove_yes": "Yes, automatically move to new address"
    }
  },
  "homepage": {
    "text": {
      "welcome_text2": "on your BHV-Knop.nl dashboard",
      "welcome_subtext": "This dashboard allows you to change the settings for your BHV-Knop.nl account. Upload a map, place your buttons in the right place and invite BHV'ers.",
      "actual_status": "Actual status of your system",
      "show_gateways": "Show gateways",
      "continue_setting_up": "Continue setting up your account",
      "continue_setting_up_subtext": "Before you can make an alarm, you have to provide a phonenumber. This is necessary to call you in case of an alarm. It is possible to receive an alarm via our app."
    }
  },
  "ra-audit-log": {
    "inline_event": {
      "read": "Read"
    }
  }
} as const

export default language