/* eslint-disable @typescript-eslint/no-unused-vars */
// in src/Menu.js
import * as React from 'react';
import { MultiLevelMenu } from '@react-admin/ra-navigation';
import { IfCanAccess } from '@react-admin/ra-rbac';
import { useEffect, useState } from 'react';

// Icons
import SensorsIcon from '@mui/icons-material/Sensors';
import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import DevicesOtherIcon from '@mui/icons-material/DevicesOther';
import LayersIcon from '@mui/icons-material/Layers';
import WebhookIcon from '@mui/icons-material/Webhook';
import MapIcon from '@mui/icons-material/Map';
import ShieldIcon from '@mui/icons-material/Shield';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import LockIcon from '@mui/icons-material/Lock';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SpatialTrackingIcon from '@mui/icons-material/SpatialTracking';
import EditNotificationsIcon from '@mui/icons-material/EditNotifications';
import InsightsIcon from '@mui/icons-material/Insights';
import BusinessIcon from '@mui/icons-material/Business';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import GroupsIcon from '@mui/icons-material/Groups';
import KeyIcon from '@mui/icons-material/Key';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import BuildIcon from '@mui/icons-material/Build';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import LinkIcon from '@mui/icons-material/Link';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import ContactsIcon from '@mui/icons-material/Contacts';
import DescriptionIcon from '@mui/icons-material/Description';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import TvIcon from '@mui/icons-material/Tv';
import SensorsOffIcon from '@mui/icons-material/SensorsOff';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import BookIcon from '@mui/icons-material/Book';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import DoorbellIcon from '@mui/icons-material/Doorbell';
import { GetListResult } from 'ra-core';
import { Identifier } from 'react-admin';
import { Asset } from '@x-guard/xgac-types/xgac';
import RoomIcon from '@mui/icons-material/Room';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import SettingsIcon from '@mui/icons-material/Settings';
import { getCurrentCustomer } from '../../lib/currentCustomer';
import authProvider from '../../utils/authProvider';
import { HasRoles } from '../../components/HasRoles';
import { xgacDataProvider } from '../../dataProviders/xgacDataProvider';

export const CustomMenu: any = (props: any) => {

  const isCustomerAdmin = authProvider.isCustomerAdmin();
  const isMapViewer = authProvider.getRoles().includes('map_viewer');
  const isAdmin = authProvider.isAdmin();
  const [hasBeacons, setHasBeacons] = useState(false);
  const [lastCustomer, setLastCustomer] = useState('');
  const currentCustomer = getCurrentCustomer()?.value || '';

  const liveMenuItems = [
    <MultiLevelMenu.Item name={'zone-overview'} to={'/reports/zone-overview'} label={'general.text.availability'}
      icon={<PersonPinIcon/>}/>,
  ];
  if (hasBeacons) {

    liveMenuItems.push(
      <MultiLevelMenu.Item name="unhealthy-beacons" to="/reports/unhealthy-beacons" label="resources.report-triggers.text.config_types.unhealthyBeacons"
        icon={<SensorsOffIcon/>}/>,
    );

  }
  if (isAdmin || isMapViewer) {

    liveMenuItems.push(
      <MultiLevelMenu.Item key={'live-map'} name="live-map" to='/live-map' label="menu.items.map" icon={<MapIcon/>}/>,
    );

  }
  useEffect(() => {

    if (currentCustomer !== lastCustomer) {

      setLastCustomer(currentCustomer);
      xgacDataProvider.getList('beacons', {
        pagination: { page: 1, perPage: 1 },
        sort: { field: 'createdAt', order: 'ASC' },
        filter: {},
      }).then((response: GetListResult<Asset & { id: Identifier }>) => {

        setHasBeacons(response.total ? response.total > 0 : false);

      });

    }

  }, [currentCustomer]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MultiLevelMenu
      initialOpen={true}
      sx={{
        paddingBottom: '2em',
      }}
      {...props}
    >
      {/* Dashboard */}
      <MultiLevelMenu.Item name="dashboard" to="/" label="Dashboard" icon={<InsightsIcon/>} sx={{ marginTop: '1em', marginBottom: '1em' }}/>
      {/* START Only visible for customer admins */}

      <HasRoles anyOf={['admin']}>
        {liveMenuItems.length > 1 && (
          <MultiLevelMenu.Item
            name="live"
            label="menu.items.live"
            icon={<PlayCircleIcon/>}
          >
            {liveMenuItems}
          </MultiLevelMenu.Item>
        )}
        {liveMenuItems.length === 1 && liveMenuItems}
      </HasRoles>
      <HasRoles anyOf={['admin', 'customer_admin']}>
        {/* Alarms */}
        <MultiLevelMenu.Item name="alarms" to='/alarms?page=1' label="menu.items.alarms" icon={<PrivacyTipIcon/>} sx={{ marginTop: '1em' }}/>
        <MultiLevelMenu.Item key={'create-alarm'} name="create-alarm" to='/alarms/create' label="menu.items.response" icon={<AddModeratorIcon/>}/>
      </HasRoles>
      {/* END Only visible for customer admins */}
      {/* START Temporary hidden except for admins */}
      <HasRoles anyOf={['admin']}>
        <HasRoles anyOf={['admin']}>
          {/* My Organisation */}
          <IfCanAccess action="edit" resource="customers">
            <MultiLevelMenu.Item name="customer" to={`/customers/${currentCustomer}`} label="menu.items.my_organisation"
              icon={<BusinessIcon/>} sx={{ marginTop: '1em' }}/>
            <MultiLevelMenu.Item
              name="alarmcenters"
              to="/customer-alarm-center-relations"
              label="resources.customer-alarm-center-relations.title"
              icon={<DoorbellIcon/>}
            />
          </IfCanAccess>
          {/* Customers */}
          <HasRoles anyOf={['admin']}>
            <>
              <IfCanAccess action="edit" resource="customers">
                <MultiLevelMenu.Item name="customers"
                  to={'/customers'}
                  label="resources.customers.text.title"
                  icon={<GroupsIcon/>}
                  sx={{ marginTop: '1em' }}
                />
              </IfCanAccess>
              <MultiLevelMenu.Item
                name="access"
                label="menu.items.access"
                icon={<KeyIcon/>}
                sx={{ marginTop: '1em' }}
              >
                <HasRoles anyOf={['developer_admin']}>
                  <MultiLevelMenu.Item name="users" to="/users" label="menu.items.root_admins" icon={<AdminPanelSettingsIcon/>}/>
                  <MultiLevelMenu.Item name="service-accounts" to="/service-accounts" label="resources.service-accounts.text.title" icon={<BuildIcon/>}/>
                  <MultiLevelMenu.Item name="roles" to="/roles" label="resources.roles.title" icon={<LockIcon/>}/>
                  <MultiLevelMenu.Item name="audit-logs" to="/audit-logs" label={'resources.auditlogs.text.title'} icon={<BookIcon/>}/>
                  <MultiLevelMenu.Item name="kiosk-defaults" to="/kiosks/defaults" label={'resources.kiosks.text.defaults'} icon={<SettingsIcon/>}/>
                </HasRoles>
              </MultiLevelMenu.Item>
            </>
          </HasRoles>
        </HasRoles>
        {/* X-Guard Alarm App */}
        <HasRoles anyOf={['admin', 'developer_admin']}>
          <MultiLevelMenu.Item
            name="x-guard-alarm-app"
            label={isCustomerAdmin ? 'menu.items.manage' : 'menu.items.alarm_app'}
            icon={!isCustomerAdmin ? <SpatialTrackingIcon/> : <ManageAccountsIcon/>}
            sx={{ marginTop: '1em' }}
          >
            <IfCanAccess action="list" resource="app-users">
              <MultiLevelMenu.Item name="app-users" to='/app-users' label="resources.app-users.text.title" icon={<PhoneIphoneIcon/>}/>
            </IfCanAccess>
            <IfCanAccess action="list" resource="asset-groups">
              <MultiLevelMenu.Item name="asset-groups" to="/asset-groups" label="menu.items.groups" icon={<GroupIcon/>}/>
            </IfCanAccess>
            <MultiLevelMenu.Item name="templates" to='/templates' label="menu.items.qr_codes" icon={<QrCode2Icon/>}/>
            <HasRoles anyOf={['admin']}>
              <MultiLevelMenu.Item name="domainlinks" to='/domainlinks' label="resources.domainlinks.title" icon={<LinkIcon/>}/>
            </HasRoles>
          </MultiLevelMenu.Item>
        </HasRoles>
        <HasRoles anyOf={['admin']}>
          {/* People & Assets */}
          <MultiLevelMenu.Item
            name="people-and-assets"
            label="menu.items.people_assets"
            icon={<SpatialTrackingIcon/>}
            sx={{ marginTop: '1em' }}
          >
            <IfCanAccess action="list" resource="users/without-app">
              <MultiLevelMenu.Item name="users/without-app" to="/users/without-app" label='menu.items.management_users' icon={<LockPersonIcon/>}/>
            </IfCanAccess>
            <IfCanAccess action="list" resource="asset-groups">
              <MultiLevelMenu.Item name="asset-groups" to="/asset-groups" label="menu.items.groups" icon={<GroupIcon/>}/>
            </IfCanAccess>
            <IfCanAccess action="list" resource="assets">
              <MultiLevelMenu.Item name="assets/without-app" to='/assets/without-app' label="menu.items.assets" icon={<PersonIcon/>}/>
            </IfCanAccess>
            <IfCanAccess action="list" resource="devices">
              <MultiLevelMenu.Item name="devices/without-app" to="/devices/without-app" label="resources.devices.text.title" icon={<DevicesOtherIcon/>}/>
            </IfCanAccess>
          </MultiLevelMenu.Item>
        </HasRoles>
        <HasRoles anyOf={['admin']}>
          {/* Resources */}
          <MultiLevelMenu.Item
            name="resources"
            label="menu.items.resources"
            icon={<WorkspacesIcon/>}
            sx={{ marginTop: '1em' }}
          >
            <IfCanAccess action="list" resource="beacons">
              <MultiLevelMenu.Item name="beacons" to='/beacons' label="resources.beacons.text.title" icon={<SensorsIcon/>}/>
            </IfCanAccess>
            <IfCanAccess action="list" resource="static-responders">
              <MultiLevelMenu.Item name="static-responders" to="/static-responders" label="resources.static-responders.text.title"
                icon={<ShieldIcon/>}/>
            </IfCanAccess>
            <IfCanAccess action="list" resource="zones">
              <MultiLevelMenu.Item name="zones" to="/zones" label="resources.zones.text.zone_list" icon={<MapIcon/>}/>
              <MultiLevelMenu.Item name="zone-map" to="/zone-map" label="resources.zones.text.title" icon={<MapIcon/>}/>
            </IfCanAccess>
            <IfCanAccess action="list" resource="overlays">
              <MultiLevelMenu.Item name="overlays" to="/overlays" label="resources.overlays.text.title" icon={<LayersIcon/>}/>
            </IfCanAccess>
            <IfCanAccess action="list" resource="contact-lists">
              <MultiLevelMenu.Item name="contact-lists" to="/contact-lists?filter=%7B%7D" label="resources.contact-lists.text.title" icon={<ContactsIcon/>}/>
            </IfCanAccess>
            <MultiLevelMenu.Item name="flic-hubs" to="/flic-hubs" label="menu.items.flic" icon={<RadioButtonCheckedIcon/>}/>
            <HasRoles anyOf={['developer_admin']}>
              <MultiLevelMenu.Item name="fingerprints" to="/fingerprints" label="Fingerprints" icon={<FingerprintIcon/>}/>
              <MultiLevelMenu.Item name={'kiosk-map'} to={'/kiosks'} label={'resources.kiosks.text.menu_title'} icon={<TvIcon/>}/>
            </HasRoles>
          </MultiLevelMenu.Item>
          {/* Alarm Handling */}
          <MultiLevelMenu.Item
            name="alarm-handling"
            label="menu.items.alarm_handling"
            icon={<NotificationsActiveIcon/>}
            sx={{ marginTop: '1em' }}
          >
            <IfCanAccess action="list" resource="observation-triggers">
              <MultiLevelMenu.Item name="observation-triggers" to="/observation-triggers" label="resources.observation-triggers.text.title"
                icon={<FilterAltIcon/>}/>
            </IfCanAccess>
            <IfCanAccess action="list" resource="webhooks">
              <MultiLevelMenu.Item name="webhooks" to="/webhooks" label="resources.webhooks.text.title" icon={<WebhookIcon/>}/>
            </IfCanAccess>
            <MultiLevelMenu.Item name="alarm-tasks" label="menu.items.alarm_tasks"
              icon={<EditNotificationsIcon/>}>
              <MultiLevelMenu.Item name="customer-links" to="/customer-links" label="resources.customer-links.text.title"
                icon={<LinkIcon/>}/>
              <MultiLevelMenu.Item name="process-templates" to="/process-templates" label="resources.process-templates.text.title"
                icon={<TextSnippetIcon/>}/>
            </MultiLevelMenu.Item>
          </MultiLevelMenu.Item>
          <MultiLevelMenu.Item
            name="reports"
            label="menu.items.reports"
            icon={<DescriptionIcon/>}
          >
            <MultiLevelMenu.Item name="report-triggers" to="/report-triggers" label="resources.report-triggers.text.title"
              icon={<NoteAddIcon/>}/>
            <MultiLevelMenu.Item name="report-trigger-runs" to="/report-trigger-runs" label="resources.reports.title" icon={<FilePresentIcon/>}/>
            <MultiLevelMenu.Item name="problematic-alarms" to='/problematic-alarms' label="menu.items.problematic_alarms" icon={<PriorityHighIcon/>}/>
          </MultiLevelMenu.Item>
        </HasRoles>
      </HasRoles>

      <HasRoles anyOf={['customer_admin']}>
        <MultiLevelMenu.Item
          name="x-guard-alarm-app"
          label={ 'menu.items.manage'}
          icon={ <SpatialTrackingIcon/>}
          sx={{ marginTop: '1em' }}
        >
          <IfCanAccess action="list" resource="app-users">
            <MultiLevelMenu.Item name="app-users" to='/app-users' label="resources.app-users.text.title" icon={<PhoneIphoneIcon/>}/>
          </IfCanAccess>
          <IfCanAccess action="list" resource="users/without-app">
            <MultiLevelMenu.Item name="users/without-app" to="/users/without-app" label={ 'menu.items.management_users'}
              icon={<LockPersonIcon/>}
            />
          </IfCanAccess>
          <IfCanAccess action="list" resource="asset-groups">
            <MultiLevelMenu.Item name="asset-groups" to="/asset-groups" label="menu.items.groups" icon={<GroupIcon/>}/>
          </IfCanAccess>
          <MultiLevelMenu.Item name="templates" to='/templates' label="menu.items.qr_codes" icon={<QrCode2Icon/>}/>
          <MultiLevelMenu.Item name="reports" to={'/report-triggers'} label={'menu.items.reports'} icon={<DescriptionIcon/>}/>
          <HasRoles anyOf={['flic_hub_tester']}>
            <MultiLevelMenu.Item name="flic-hubs" to="/flic-hubs" label="menu.items.flic" icon={<RadioButtonCheckedIcon/>}/>
          </HasRoles>
        </MultiLevelMenu.Item>
        <MultiLevelMenu.Item
          name="maps"
          label="menu.items.maps"
          icon={<RoomIcon/>}
        >
          <MultiLevelMenu.Item name="zone-map" to="/zone-map" label="resources.zones.text.title" icon={<MapIcon/>}/>
          <MultiLevelMenu.Item name="overlays" to="/overlays" label="resources.overlays.text.title" icon={<LayersIcon/>}/>
        </MultiLevelMenu.Item>
        <MultiLevelMenu.Item
          name="live"
          label="menu.items.live"
          icon={<PlayCircleIcon/>}
        >
          {liveMenuItems}
        </MultiLevelMenu.Item>
      </HasRoles>
    </MultiLevelMenu>
  );

};
